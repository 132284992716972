import React from 'react'
import usdtImg from '../../Assets/images/usdt.png'
import twtImg from '../../Assets/images/TWT.png'
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { CModal, CModalHeader, CModalBody, CModalTitle, CDropdown, CDropdownToggle, CFormSwitch, CFormRange } from '@coreui/react';
// import CTooltip from '@coreui/react/src/components/tooltip/CTooltip'
import Tooltip from "react-simple-tooltip"


const RemoveLiquidityModal = (props) => {

    return (
        <>
            {/* Preview Modal */}
            <CModal alignment="center" visible={props.visibleRemoveLiquidity} onClose={() => props.setRemoveLiquidity(false)} className="previewModalBox">
                <CModalHeader>
                    <CModalTitle>Remove Liquidity</CModalTitle>
                    <CDropdown>
                        <CDropdownToggle color="secondary"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg></CDropdownToggle>
                        <div className='settingDownToggle'>
                            <div className='p-3 inrdrp'>
                                <ul>
                                    <li>
                                        <h3>Settings</h3>
                                        <p>
                                            <span>Slippage tolerance</span>
                                            <Tooltip content="Lorem Ipsum">
                                                <svg fill="#000000" width="64px" height="64px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
                                                    <g id="SVGRepo_bgCarrier" stroke-width="0" />
                                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
                                                    <g id="SVGRepo_iconCarrier"> <path d="M960 1807.059c-467.125 0-847.059-379.934-847.059-847.059 0-467.125 379.934-847.059 847.059-847.059 467.125 0 847.059 379.934 847.059 847.059 0 467.125-379.934 847.059-847.059 847.059M960 0C430.645 0 0 430.645 0 960s430.645 960 960 960 960-430.645 960-960S1489.355 0 960 0m.056 1355.181 56.471.113h-56.47v-.113ZM752.64 409.65c83.69-64.715 191.661-86.4 296.696-59.294 114.862 29.703 208.264 123.106 237.968 237.967 23.378 90.466 10.729 183.303-35.464 261.459-45.515 77.026-121.186 133.948-207.586 156.084-13.779 3.502-27.783 14.796-27.783 31.85v91.708H903.529v-91.708c0-66.07 46.306-124.123 112.716-141.29 57.6-14.682 107.971-52.63 138.353-104.018 30.833-52.292 39.19-114.749 23.378-175.85-19.651-75.67-81.204-137.223-156.875-156.875-70.927-18.184-143.548-3.953-199.341 39.303-55.68 43.144-87.642 108.311-87.642 178.673H621.176c0-105.6 47.888-203.294 131.464-268.01Zm207.416 832.704c-62.343 0-112.94 50.71-112.94 112.941 0 62.23 50.597 112.941 112.94 112.941 62.231 0 112.942-50.71 112.942-112.94 0-62.231-50.71-112.942-112.942-112.942Z" fill-rule="evenodd" /> </g>
                                                </svg>
                                            </Tooltip>
                                        </p>
                                        <div className='rowBox toleranceBox'>
                                            <button>Auto</button>
                                            <div className='inputBox'>
                                                <input type="text" placeholder='0.50' />
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <p>
                                            <span>Transaction deadline</span>
                                            <Tooltip content="Lorem Ipsum">
                                                <svg fill="#000000" width="64px" height="64px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
                                                    <g id="SVGRepo_bgCarrier" stroke-width="0" />
                                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
                                                    <g id="SVGRepo_iconCarrier"> <path d="M960 1807.059c-467.125 0-847.059-379.934-847.059-847.059 0-467.125 379.934-847.059 847.059-847.059 467.125 0 847.059 379.934 847.059 847.059 0 467.125-379.934 847.059-847.059 847.059M960 0C430.645 0 0 430.645 0 960s430.645 960 960 960 960-430.645 960-960S1489.355 0 960 0m.056 1355.181 56.471.113h-56.47v-.113ZM752.64 409.65c83.69-64.715 191.661-86.4 296.696-59.294 114.862 29.703 208.264 123.106 237.968 237.967 23.378 90.466 10.729 183.303-35.464 261.459-45.515 77.026-121.186 133.948-207.586 156.084-13.779 3.502-27.783 14.796-27.783 31.85v91.708H903.529v-91.708c0-66.07 46.306-124.123 112.716-141.29 57.6-14.682 107.971-52.63 138.353-104.018 30.833-52.292 39.19-114.749 23.378-175.85-19.651-75.67-81.204-137.223-156.875-156.875-70.927-18.184-143.548-3.953-199.341 39.303-55.68 43.144-87.642 108.311-87.642 178.673H621.176c0-105.6 47.888-203.294 131.464-268.01Zm207.416 832.704c-62.343 0-112.94 50.71-112.94 112.941 0 62.23 50.597 112.941 112.94 112.941 62.231 0 112.942-50.71 112.942-112.94 0-62.231-50.71-112.942-112.942-112.942Z" fill-rule="evenodd" /> </g>
                                                </svg>
                                            </Tooltip>
                                        </p>
                                        <div className='rowBox deadlineBox'>
                                            <div className='inputBox'>
                                                <input type="text" placeholder='0.50' />
                                            </div>
                                            <span>Minutes</span>
                                        </div>
                                    </li>
                                    <li>
                                        <h3>Interface Settings</h3>
                                        <div className='rowBox rowBxinte'>
                                            <p>
                                                <span>Auto Router API</span>
                                                <Tooltip content="Lorem Ipsum">
                                                    <svg fill="#000000" width="64px" height="64px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
                                                        <g id="SVGRepo_bgCarrier" stroke-width="0" />
                                                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
                                                        <g id="SVGRepo_iconCarrier"> <path d="M960 1807.059c-467.125 0-847.059-379.934-847.059-847.059 0-467.125 379.934-847.059 847.059-847.059 467.125 0 847.059 379.934 847.059 847.059 0 467.125-379.934 847.059-847.059 847.059M960 0C430.645 0 0 430.645 0 960s430.645 960 960 960 960-430.645 960-960S1489.355 0 960 0m.056 1355.181 56.471.113h-56.47v-.113ZM752.64 409.65c83.69-64.715 191.661-86.4 296.696-59.294 114.862 29.703 208.264 123.106 237.968 237.967 23.378 90.466 10.729 183.303-35.464 261.459-45.515 77.026-121.186 133.948-207.586 156.084-13.779 3.502-27.783 14.796-27.783 31.85v91.708H903.529v-91.708c0-66.07 46.306-124.123 112.716-141.29 57.6-14.682 107.971-52.63 138.353-104.018 30.833-52.292 39.19-114.749 23.378-175.85-19.651-75.67-81.204-137.223-156.875-156.875-70.927-18.184-143.548-3.953-199.341 39.303-55.68 43.144-87.642 108.311-87.642 178.673H621.176c0-105.6 47.888-203.294 131.464-268.01Zm207.416 832.704c-62.343 0-112.94 50.71-112.94 112.941 0 62.23 50.597 112.941 112.94 112.941 62.231 0 112.942-50.71 112.942-112.94 0-62.231-50.71-112.942-112.942-112.942Z" fill-rule="evenodd" /> </g>
                                                    </svg>
                                                </Tooltip>
                                            </p>
                                            <CFormSwitch />
                                        </div>
                                        <div className='rowBox rowBxinte'>
                                            <p>
                                                <span>Expert Mode</span>
                                                <Tooltip content="Lorem Ipsum">
                                                    <svg fill="#000000" width="64px" height="64px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
                                                        <g id="SVGRepo_bgCarrier" stroke-width="0" />
                                                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
                                                        <g id="SVGRepo_iconCarrier"> <path d="M960 1807.059c-467.125 0-847.059-379.934-847.059-847.059 0-467.125 379.934-847.059 847.059-847.059 467.125 0 847.059 379.934 847.059 847.059 0 467.125-379.934 847.059-847.059 847.059M960 0C430.645 0 0 430.645 0 960s430.645 960 960 960 960-430.645 960-960S1489.355 0 960 0m.056 1355.181 56.471.113h-56.47v-.113ZM752.64 409.65c83.69-64.715 191.661-86.4 296.696-59.294 114.862 29.703 208.264 123.106 237.968 237.967 23.378 90.466 10.729 183.303-35.464 261.459-45.515 77.026-121.186 133.948-207.586 156.084-13.779 3.502-27.783 14.796-27.783 31.85v91.708H903.529v-91.708c0-66.07 46.306-124.123 112.716-141.29 57.6-14.682 107.971-52.63 138.353-104.018 30.833-52.292 39.19-114.749 23.378-175.85-19.651-75.67-81.204-137.223-156.875-156.875-70.927-18.184-143.548-3.953-199.341 39.303-55.68 43.144-87.642 108.311-87.642 178.673H621.176c0-105.6 47.888-203.294 131.464-268.01Zm207.416 832.704c-62.343 0-112.94 50.71-112.94 112.941 0 62.23 50.597 112.941 112.94 112.941 62.231 0 112.942-50.71 112.942-112.94 0-62.231-50.71-112.942-112.942-112.942Z" fill-rule="evenodd" /> </g>
                                                    </svg>
                                                </Tooltip>
                                            </p>
                                            <CFormSwitch />
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </CDropdown>
                </CModalHeader>
                <CModalBody>
                    <div className="topheadTtl">
                        <h2 className="imgBx">
                            <img src={twtImg} alt="TWT" />
                            <img src={usdtImg} alt="USDT" />
                            <strong>OC/POL</strong>
                        </h2>
                        <span>In Rage</span>
                    </div>
                    <div className="removeLiqBx">
                        <div className='amountBox'>
                            <h2>Amount</h2>
                            <div className='countBox'>
                                <strong>30%</strong>
                                <ul>
                                    <li>25%</li>
                                    <li>50%</li>
                                    <li>75%</li>
                                    <li>Max</li>
                                </ul>
                            </div>
                            <CFormRange min={0} max={100} defaultValue="30" />
                        </div>
                        <div className='amountBox'>
                            <div className="topheadTtl">
                                <span>Pooled USDT:</span>
                                <h2 className="imgBx">
                                    <strong>0</strong>
                                    <img src={usdtImg} alt="USDT" />
                                </h2>
                            </div>
                            <div className="topheadTtl">
                                <span>Pooled TWT:</span>
                                <h2 className="imgBx">
                                    <strong>0</strong>
                                    <img src={twtImg} alt="TWT" />
                                </h2>
                            </div>
                        </div>
                        <div className='amountBox'>
                            <div className="topheadTtl">
                                <span>Collect as WTWT</span>
                                <CFormSwitch />
                            </div>
                        </div>

                        <button className="enterPercentBtn">Enter a percent</button>
                    </div>
                </CModalBody>
            </CModal>
        </>
    )
}

export default RemoveLiquidityModal